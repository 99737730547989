<template>
  <div>
    <a-card>
      <template slot="title">
        <strong>Instansi</strong>
        <a-button
          class="btn btn-outline-primary ml-4"
          @click="sendToForm('Create')"
          >Tambah Data</a-button
        >
        <a-button class="btn btn-outline-success ml-2" @click="getData"
          >Refresh Data</a-button
        >
        <a-button class="btn btn-outline-success float-right" @click="exportExcel"
          >Export Data</a-button
        >
      </template>
      <a-table
        :columns="columns"
        :dataSource="data"
        size="small"
        :customRow="customRow"
        :pagination="{
          hideOnSinglePage: true,
          defaultPageSize: 10,
          showQuickJumper: true,
          showSizeChanger: true,
          showTotal: (total) => `Total ${total} items`,
          pageSizeOptions: ['10', '20', '30'],
        }"
      >
        <div
          slot="filterDropdown"
          slot-scope="{
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            column,
          }"
          style="padding: 8px;"
        >
          <a-input
            v-ant-ref="(c) => (searchInput = c)"
            :placeholder="`Search ${column.title}`"
            :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block;"
            @change="
              (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
            "
            @pressEnter="
              () => handleSearch(selectedKeys, confirm, column.dataIndex)
            "
          />
          <a-button
            type="primary"
            icon="search"
            size="small"
            style="width: 90px; margin-right: 8px;"
            @click="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
          >
            Search
          </a-button>
          <a-button
            size="small"
            style="width: 90px;"
            @click="() => handleReset(clearFilters)"
          >
            Reset
          </a-button>
        </div>
        <a-icon
          slot="filterIcon"
          slot-scope="filtered"
          type="search"
          :style="{ color: filtered ? '#108ee9' : undefined }"
        />
        <template slot="customRender" slot-scope="text, record, index, column">
          <span v-if="searchText && searchedColumn === column.dataIndex">
            <template
              v-for="(fragment, i) in text
                .toString()
                .split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))"
            >
              <mark
                v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                :key="i"
                class="highlight"
                >{{ fragment }}</mark
              >
              <template v-else>{{ fragment }}</template>
            </template>
          </span>
          <template v-else>
            <span>
              {{ text }}
            </span>
          </template>
        </template>
        <span slot="action" slot-scope="text, record">
          <a-icon
            type="edit"
            class="text-warning"
            @click="sendToForm('Update', record, record.id)"
          />
          <a-divider type="vertical" />
          <a-icon
            type="delete"
            class="text-danger"
            @click="showDeleteConfirm(record)"
          />
        </span>
        <template slot="appList" slot-scope="text, record">
          <a-tag v-for="(data, index) in record.appLists" :key="index">{{
            data.appName
          }}</a-tag>
        </template>
        <template slot="currency" slot-scope="text">
          {{ formatCurrency(text) }}
        </template>
      </a-table>
    </a-card>
    <formModal ref="formModal" :key="componentKey" :table="table" />
  </div>
</template>

<script>
import * as lou from '@/services/data/lou'
import { Modal } from 'ant-design-vue'
import formModal from './form'
import moment from 'moment'
import NProgress from 'nprogress'

export default {
  components: {
    formModal,
  },
  data() {
    return {
      name: 'TablesAntdBasic',
      searchText: '',
      searchInput: null,
      searchedColumn: '',
      popinput: {
        password: '',
        re_password: '',
      },
      rowIndexGotSpan: [],
      columns: [
        {
          title: 'Action',
          key: 'action',
          width: 70,
          scopedSlots: { customRender: 'action' },
        },
        {
          title: 'Keterangan',
          dataIndex: 'keterangan',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) =>
            record.keterangan
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
        },
      ],
      selectedidbpr: '',
      datapermission: [],
      table: 'instansi',
      form: this.$form.createForm(this, { name: 'Form ' + this.table }),
      data: [],
      backupdata: [],
      componentKey: 0,
    }
  },
  mounted: function () {
    this.getData()
  },
  methods: {
    findName(record) {
      if (record.appLists.length === 0) {
        return 'Tidak ada List app'
      } else {
        var tindex = record.appLists.findIndex(
          (x) => x.id === record.id_Default_App,
        )
        // console.log('tindex', tindex)
        return record.appLists[tindex].appName
      }
    },
    hide(index) {
      this.data[index].changePassPop = false
      this.popinput = {
        password: '',
        re_password: '',
      }
    },
    sendToForm(action, data = {}) {
      this.$refs.formModal.showModal(action, data)
    },
    // async getMaster() {
    //   var res = await lou.customUrlGet2('permission', false, false)
    //   var res1 = await lou.customUrlGet2('roles', false, false)
    //   if (res) {
    //     this.datapermission = res.data
    //   }
    //   if (res1) {
    //     this.dataroles = res1.data
    //   }
    //   this.getData()
    // },
    async getData(onclick = false) {
      var res = await lou.customUrlGet2('' + this.table, false, false)
      if (res) {
        this.data = []
        this.data = this.$g.clone(res.data)
        this.backupdata = this.$g.clone(res.data)
        if (onclick) {
          lou.shownotif('Refresh', 'Data telah di refresh.')
        }
      }
    },
    showDeleteConfirm(deldata) {
      Modal.confirm({
        title: 'Are you sure delete this data?',
        content: "You can't prevent this!!! ",
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk: async () => {
          var response = await lou.customUrlDelete(
            'gaji/' + this.table + '/' + deldata.id,
            true,
            true,
          )
          if (response) {
            this.getData()
          }
        },
        onCancel: () => {
          // console.log('Cancel')
        },
      })
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm()
      this.searchText = selectedKeys[0]
      this.searchedColumn = dataIndex
    },

    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
    customRow(record, index) {
      return {
        on: {
          click: (event) => {
            // console.log('record', record)
            // console.log('index', index)
            // console.log('event', event)
            // this.nasabahDetail(record)
          },
          contextmenu: (event) => {
            event.preventDefault()
          },
        },
      }
    },
    handleReset(clearFilters) {
      clearFilters()
      this.searchText = ''
    },
    formatCurrency(v) {
      return lou.curency(v)
    },
    async exportExcel() {
      NProgress.start()
      const ExcelJS = require('exceljs')
      const workbook = new ExcelJS.Workbook()
      workbook.creator = 'LB-BPRS Heasoft System'
      workbook.lastModifiedBy = 'Me'
      workbook.created = new Date()
      workbook.modified = new Date()
      workbook.lastPrinted = new Date()
      var alphabeth = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
      const worksheet = workbook.addWorksheet(moment().format('DD-MM-YYYY'))

      // Table Data
      var column = this.$g.clone(this.columns)
      var tdata = this.$g.clone(this.data)
      var data = []
      tdata.forEach(element => {
        var retv = {
          ...element,
          tgl: element.tgl === null ? '' : moment(element.tgl, 'YYYY-MM-DD').format('DD-MM-YYYY'),
        }
        data.push(retv)
      })
      var cfirst = 0
      var cloop = 0
      var dincrement = 2
      var columnLocation = []
      for (let i = 0; i < column.length; i++) {
        const element = column[i]
        var cloopni = cloop - 1
        var hname = element.title.toString()
        if (hname !== 'Action') {
          if (cloop === 0) {
            columnLocation.push({
              location: alphabeth[cfirst],
              field: element.dataIndex,
            })
            worksheet.getCell(alphabeth[cfirst] + '1').value = hname.replaceAll(' ', '')
          } else {
            columnLocation.push({
              location: alphabeth[cloopni] + alphabeth[cfirst],
              field: element.dataIndex,
            })
            worksheet.getCell(alphabeth[cloopni] + alphabeth[cfirst] + '1').value = hname.replaceAll(' ', '')
          }
          for (let u = 0; u < data.length; u++) {
            const el = data[u]
            if (cloop === 0) {
              worksheet.getCell(alphabeth[cfirst] + dincrement).value = el[element.dataIndex]
            } else {
              worksheet.getCell(alphabeth[cloopni] + alphabeth[cfirst] + dincrement).value = el[element.dataIndex]
            }
            var checklast = u + 1
            if (checklast === data.length) {
              dincrement = 2
            } else {
              dincrement += 1
            }
          }
          if (cfirst === 25) {
            cfirst = 0
            cloop += 1
          } else {
            cfirst += 1
          }
        }
      }

      /* For sum data */
      // var last = data.length + 3
      // worksheet.mergeCells('A' + last + ':B' + last)
      // worksheet.getCell('A' + last).value = 'Total'
      // for (let ti = 0; ti < this.columnsTotal.length; ti++) {
      //   const element = this.columnsTotal[ti]
      //   var targetLoc = columnLocation.findIndex(x => x.field === element.dataIndex)
      //   if (targetLoc >= 0) {
      //     worksheet.getCell(columnLocation[targetLoc].location + last).value = this.rowDataTotal[0][element.dataIndex]
      //   }
      //   // console.log(columnLocation[targetLoc].location + last, this.rowDataTotal[0][element.dataIndex])
      // }

      // write to a new buffer
      const buffer = await workbook.xlsx.writeBuffer()
      var blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
      var link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = 'Data Master Instansi.xlsx'
      NProgress.done()
      link.click()
    },
  },
}
</script>
